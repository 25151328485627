import React, { FC, useState, useEffect } from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import { useParams } from 'react-router-dom';
import {Table, Button, Container, Row, Col} from "react-bootstrap";
import * as api from '../../services/api';
import { useHistory } from 'react-router-dom';
import {OnDemandWorkout} from "tlm-common";
import {BadgesFromArray} from "../../components/badges/BadgesfromArray";
import {GoogleCloudImg} from "../../components/images/GoogleCloudImg";
import {GoogleCloudVideo} from "../../components/images/GoogleCloudVideo";
import JSONPretty from "react-json-pretty";
import {VerticalGap} from "../../components/layout/VerticalGap";

export const OnDemandWorkoutDetailPage: FC = () => {

  const { id } = useParams<{id: string}>();
  const [ odw, setOdw ] = useState<OnDemandWorkout | null>(null);
  const [ loading, setLoading ] = useState(true);
  const history = useHistory();

  useEffect(()=>{
    async function load(){
      setLoading(true);
      const ex = await api.onDemandWorkouts.get(id);
      setOdw(ex);
      setLoading(false);
    }

    if (id) {
      load();
    }

  }, [id]);

  if (!odw) {
    return null;
  }

  return (
    <PageContainer title={odw?.title} loading={loading} rightSide={<Button onClick={()=>history.push(`/odw/edit/${id}`)} size={'sm'}>EDIT</Button>}>
        <Container>
          <Row>
            <Col>
              <Table striped className="mt-3">
                <tr>
                  <td>Title</td>
                  <td>{odw?.title}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{odw?.description}</td>
                </tr>
                <tr>
                  <td>Fitness Level</td>
                  <td>{odw?.fitnessLevel || 'beginner'}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td><p>{odw?.duration}</p></td>
                </tr>
                <tr>
                  <td>Published</td>
                  <td><p>{odw?.published ? 'yes' : 'no'}</p></td>
                </tr>
              </Table>
            </Col>
            <Col>
              <GoogleCloudImg meta={odw?.thumb} style={{width: '25vw'}} label="Thumbnail"/>
              <VerticalGap gapSize={30}/>
              <GoogleCloudVideo meta={odw?.media} label="Video"/>
            </Col>
          </Row>
        </Container>
    </PageContainer>
  );
};
