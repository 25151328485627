import React, {FC, useEffect, useState} from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import {useHistory, useParams} from 'react-router-dom';
import {Alert, Button, Form, Modal, Container, Row, Col} from "react-bootstrap";
import * as api from '../../services/api';
import {GCSFile, OnDemandWorkout, Journey, BARE_JOURNEY} from 'tlm-common';
import {GCSFilePicker} from "../../components/pickers/GCSFilePicker";
import {useOnDemandWorkouts} from "../../hooks/useOnDemandWorkouts";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {JourneyWorkoutCard} from "./JourneyWorkoutCard";
import { toast } from 'react-toastify';


export const AddEditJourneyPage: FC = () => {

  const {id} = useParams<{ id: string }>();
  const [journey, setJourney] = useState<Journey>(BARE_JOURNEY);
  const [alertText, setAlertText] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const {workouts} = useOnDemandWorkouts();

  useEffect(() => {
    async function load() {
      setLoading(true);
      setJourney(await api.journeys.get(id));
      setLoading(false);
    }

    if (id !== 'new') {
      load();
    }

  }, [id]);

  const existing = id !== 'new';
  const pageTitle = existing ? `Edit Journey: ${journey.name}` : `Add Journey`;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(journey);
    try {
      if (!existing) {
        console.log('Adding Journey');
        await api.journeys.createNew({...journey});
        toast.success('Journey created');
        history.goBack();
      } else {
        console.log('Modifying Journey');
        await api.journeys.modifyFields(id, journey);
        toast.success('Journey modified!');
        history.goBack();
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const handleDelete = async () => {
    setShowModal(true);
  }

  const confirmDelete = async (confirmed: boolean) => {
    setShowModal(false);
    if (confirmed) {
      await api.journeys.destroy(id);
      history.go(-2);
    }
  }

  const updateField = (field: string, value: any) => {
    //console.log('Updating field ', field, ' to ', value);
    setJourney({...journey, [field]: value});
  }

  const handlePublishedChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Published changed to ${ev.target.checked}`);
    setJourney({...journey, published: ev.target.checked});
  }

  const handleOnDragEnd = (result: DropResult) => {
    console.log(result);
    const {destination, source} = result;
    // an add
    if ((destination?.droppableId === 'dest') && (source.droppableId === 'source')) {
      setJourney({...journey, videos: [...journey.videos, workouts[source.index]]});
    }
    // a delete
    if ((source.droppableId === 'dest') && (!destination)) {
      // eslint-disable-next-line no-template-curly-in-string
      console.log(`Delete item ${source.index}!`);
      const videos = [...journey.videos];
      videos.splice(source.index, 1);
      setJourney({...journey, videos });
    }

    if ((source.droppableId === 'dest') && (destination?.droppableId === 'dest')) {
      console.log(`ODW reorder op. source: ${source?.index} dest ${destination?.index}`);
      const videos = [...journey.videos];
      // sexy, no? Swaps the array elements
      [ videos[destination?.index], videos[source?.index]] = [ videos[source?.index], videos[destination?.index]];
      setJourney({...journey, videos });
    }
  }

  const canSubmit = journey.name && journey.description && journey.videos.length;

  return (
    <PageContainer title={pageTitle} loading={loading}>
      <Button variant="primary" onClick={handleSubmit}
              className="float-right"
              disabled={!canSubmit}>
        SAVE
      </Button>
      {alertText && <Alert variant="danger" onClose={() => setAlertText('')} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>{alertText}</p>
      </Alert>}
      {existing && <Button variant="danger" onClick={handleDelete}>Delete Journey</Button>}
      <Form className="mt-5" onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Journey Name</Form.Label>
          <Form.Control type="text" placeholder="Journey Name" onChange={event => {
            updateField('name', event.target.value)
          }} value={journey.name}/>
        </Form.Group>
        <Form.Group controlId="tag">
          <Form.Label>Journey Tag</Form.Label>
          <Form.Control type="text" placeholder="tag, this is used by the algorithm" onChange={event => {
            updateField('tag', event.target.value)
          }} value={journey.tag}/>
          <p className="text-muted">Tag must be one of the tags used by the Journey algorithm or it will never get delivered to clients :).</p>
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" placeholder="Journey Description" onChange={event => {
            updateField('description', event.target.value)
          }} value={journey.description}/>
        </Form.Group>
        <Form.Group controlId="internal">
          <Form.Label>Internal Description</Form.Label>
          <Form.Control type="text" placeholder="Internal Description (clients will not see)" onChange={event => {
            updateField('internalDescription', event.target.value)
          }} value={journey.internalDescription}/>
        </Form.Group>
        <Form.Check
          type="switch"
          id="publish-switch"
          label="Published"
          checked={journey?.published}
          onChange={handlePublishedChange}/>
        <p className="text-muted font-italic">Published journeys are available for use in the Lucy app.</p>
      </Form>
      <h4 className="mt-2 mb-2">Edit Workouts</h4>
      <p className="font-italic text-muted"> Drag from Available to Journey to add on-demand workouts. Drag off the
        journey box to remove from Journey.</p>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Container className="mt-2">
          <Row>
            <Col>
              <Droppable droppableId="source">
                {(provided) => (
                  <div {...provided.droppableProps}
                       style={styles.droppableContainer}
                       ref={provided.innerRef}>
                    <p className="text-muted text-center">Available On-Demand</p>
                    {workouts.map((odw, index) => (
                      <Draggable draggableId={odw.docId} index={index} key={odw.title}>
                        {(provided) => (
                          <div {...provided.draggableProps}
                               {...provided.dragHandleProps}
                               ref={provided.innerRef}
                               className="center">
                            <JourneyWorkoutCard workout={odw}/>
                          </div>
                        )}
                      </Draggable>))}
                    {provided.placeholder}
                  </div>)
                }
              </Droppable>
            </Col>
            <Col>
              <Droppable droppableId="dest">
                {(provided) => (
                  <div {...provided.droppableProps}
                       style={styles.droppableContainer}
                       ref={provided.innerRef}>
                    <p className="text-muted text-center">Journey</p>
                    {journey.videos.map((odw, index) => (
                      <Draggable draggableId={'j:' + odw.docId + index}  index={index} key={`odw.title+${index}`}>
                        {(provided) => (
                          <div {...provided.draggableProps}
                               {...provided.dragHandleProps}
                               ref={provided.innerRef}>
                            <JourneyWorkoutCard workout={odw}/>
                          </div>
                        )}
                      </Draggable>))}
                    {provided.placeholder}
                  </div>)
                }
              </Droppable>
            </Col>
          </Row>
        </Container>

      </DragDropContext>
      <Modal show={showModal} onHide={() => confirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Journey</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete journey {journey.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => confirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmDelete(true)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );
};

const styles = {
  droppableContainer: {
    border: '2px solid #333333',
    minHeight: '400px',
    width: '250px',
    padding: '10px'
  }
}
