import React, { FC, useState, useEffect } from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import { useParams } from 'react-router-dom';
import {Table, Button, Container, Row, Col, Badge} from "react-bootstrap";
import * as api from '../../services/api';
import { useHistory } from 'react-router-dom';
import {Journey} from "tlm-common";
import {GoogleCloudImg} from "../../components/images/GoogleCloudImg";
import {GoogleCloudVideo} from "../../components/images/GoogleCloudVideo";
import {VerticalGap} from "../../components/layout/VerticalGap";
import {JourneyWorkoutCard} from "./JourneyWorkoutCard";

export const JourneyDetailPage: FC = () => {

  const { id } = useParams<{id: string}>();
  const [journey, setJourney] = useState<Journey | null>(null);
  const [ loading, setLoading ] = useState(true);
  const history = useHistory();

  useEffect(()=>{
    async function load(){
      setLoading(true);
      const j = await api.journeys.get(id);
      setJourney( j );
      setLoading(false);
    }

    if (id) {
      load();
    }

  }, [id]);

  if (!journey) {
    return null;
  }

  return (
    <PageContainer title={journey?.name} loading={loading} rightSide={<Button onClick={()=>history.push(`/journey/edit/${id}`)} size={'sm'}>EDIT</Button>}>
        <Container>
          <Row>
            <Col>
              <Table striped className="mt-3">
                <tr>
                  <td>Name</td>
                  <td>{journey?.name}</td>
                </tr>
                <tr>
                  <td>Tag</td>
                  <td><Badge variant="success">{journey?.tag}</Badge></td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{journey?.description}</td>
                </tr>
                <tr>
                  <td>Internal Description</td>
                  <td>{journey?.internalDescription}</td>
                </tr>
                <tr>
                  <td>Workouts</td>
                  <td>{ journey?.videos?.map( odw => <JourneyWorkoutCard workout={odw}/>)}</td>
                </tr>
                <tr>
                  <td>Published</td>
                  <td><p>{journey?.published ? 'yes' : 'no'}</p></td>
                </tr>
              </Table>
            </Col>
          </Row>
        </Container>
    </PageContainer>
  );
};
