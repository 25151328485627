
import {firestore} from "../../firebase/firebaseCore";
import {Journey} from "tlm-common";

const c = firestore.collection('journeys');

export async function getAll() {
  const exQuerySnapshot = await c.get();
  return exQuerySnapshot.docs.map(d => ({...d.data(), docId: d.id })) as Journey[];
}

export async function get(docId: string) {
  const doc = await c.doc(docId).get();
  return {...doc.data(), docId: doc.data()?.id } as Journey;
}

export async function createNew( exercise: Partial<Journey> ){
  const newDoc = await c.add(exercise);
  return newDoc.id;
}

export async function modifyFields( docId: string, newFields: Partial<Journey>){
  delete newFields.docId;
  return c.doc(docId).update(newFields);
}

export async function destroy( docId: string ){
  return c.doc(docId).delete();
}
