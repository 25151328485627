import React, {FC} from 'react';
import {OnDemandWorkout} from 'tlm-common';


interface Props {
  workout: OnDemandWorkout;
}

export const JourneyWorkoutCard: FC<Props> = ({workout}) => {

  return (
    <div className="border-primary" style={styles.cardContainer}>
      <p>{workout.title}</p>
    </div>
  );
};

const styles = {
  cardContainer: {
    width: '200px',
    padding: '10px',
    border: '1px solid green',
    borderRadius: '3px',
    marginBottom: '5px',
    backgroundColor: '#fff'
  }
}
