import React, {FC} from 'react';
import {Form} from "react-bootstrap";
import * as _ from 'lodash';
import {ValueDescription} from "tlm-common";
import JSONPretty from "react-json-pretty";

type Props = {
  label: string;
  onChange: (field: string, value: any) => void;
  value: string[];
  fieldName: string;
  allChoices: ValueDescription[];
  showData?: boolean;
}

export const CheckboxGroupValueDescriptionArrays: FC<Props> = ({label, onChange, value, fieldName, allChoices, showData = false}) => {

  const checkChanged = (choice: string, isChecked: boolean) => {
    const newVals = isChecked ? _.uniq([...value, choice]) : _.without(value, choice);
    onChange(fieldName, newVals);
  }

  const renderBoxes = () => {
    return allChoices.map(c =>
      <Form.Check
        type="checkbox"
        key={c.value}
        label={c.description}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => checkChanged(c.value as string, ev.target.checked)}
        checked={value.includes(c.value as string)}
      />);
  }

  return (
    <Form.Group controlId={label}>
      <Form.Label>{label}</Form.Label>
      {renderBoxes()}
      { showData ? <JSONPretty json={value}/> : null }
    </Form.Group>
  );
};
