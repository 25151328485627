import React, {FC, useEffect, useState} from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import {useHistory, useParams} from 'react-router-dom';
import {Alert, Button, Form, Modal } from "react-bootstrap";
import * as api from '../../services/api';
import {GCSFile, OnDemandWorkout, BARE_ODW_MODEL} from 'tlm-common';
import {Selector} from "../../components/formElements/Selector";
import {GCSFilePicker} from "../../components/pickers/GCSFilePicker";
import {GoogleCloudImg} from "../../components/images/GoogleCloudImg";
import {GoogleCloudVideo} from "../../components/images/GoogleCloudVideo";
import {GapSize, VerticalGap} from "../../components/layout/VerticalGap";

export const AddEditOnDemandPage: FC = () => {

  const {id} = useParams<{ id: string }>();
  const [odw, setOdw] = useState<OnDemandWorkout>(BARE_ODW_MODEL);
  const [alertText, setAlertText] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [pickerMimeType, setPickerMimeType] = useState('');
  const [pickerPrefix, setPickerPrefix] = useState('');
  const [targetPickerField, setTargetPickerField] = useState('');

  const history = useHistory();

  useEffect(() => {
    async function load() {
      setLoading(true);
      const od = await api.onDemandWorkouts.get(id);
      setOdw(od);
      setLoading(false);
    }

    if (id!=='new') {
      load();
    }

  }, [id]);

  const existing = id !== 'new';
  const pageTitle = existing ? `Edit On Demand Workout: ${odw.title}` : `Add On Demand Workout ${odw.title}`;

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log(odw);
    if (!existing) {
      console.log('Adding ODW');
      await api.onDemandWorkouts.createNew(odw);
      history.goBack();
    } else {
      console.log('Modifying ODW');
      await api.onDemandWorkouts.modifyFields(id, odw);
      history.goBack();
    }
  }

  const handleDelete = async () => {
    setShowModal(true);
  }

  const confirmDelete = async (confirmed: boolean) => {
    setShowModal(false);
    if (confirmed) {
      await api.onDemandWorkouts.destroy(id);
      history.go(-2);
    }
  }

  const updateField = (field: string, value: any) => {
    //console.log('Updating field ', field, ' to ', value);
    setOdw({...odw, [field]: value});
  }

  const handlePublishedChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Published changed to ${ev.target.checked}`);
    setOdw({...odw, published: ev.target.checked});
  }

  const handleMediaChange = (mediaField: string, prefix: string, type: string) => {
    setTargetPickerField(mediaField);
    setPickerPrefix(prefix);
    setPickerMimeType(type);
    setShowPicker(true);
  }

  const handleMediaPick = (media: GCSFile | {}) => {
    setOdw({...odw, [targetPickerField]: media});
  }

  const removeMedia = (fieldName: string) => {
    setOdw({...odw, [fieldName]: {}});
  }

  return (
    <PageContainer title={pageTitle} loading={loading}>
      <Button variant="primary" onClick={handleSubmit} className="float-right">
        SAVE
      </Button>
      {alertText && <Alert variant="danger" onClose={() => setAlertText('')} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>{alertText}</p>
      </Alert>}
      {existing && <Button variant="danger" onClick={handleDelete}>Delete On Demand Workout</Button>}
      <Form className="mt-5" onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Workout Title" onChange={event => {
            updateField('title', event.target.value)
          }} value={odw.title}/>
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" placeholder="Workout Description" onChange={event => {
            updateField('description', event.target.value)
          }} value={odw.description}/>
        </Form.Group>
        <Form.Group controlId="duration">
          <Form.Label>Duration (minutes)</Form.Label>
          <Form.Control type="text" placeholder="Workout Duration" onChange={event => {
            updateField('duration', event.target.value)
          }} value={odw.duration}/>
        </Form.Group>
        <Selector label="Fitness Level" onChange={updateField} value={odw.fitnessLevel}
                  options={['beginner', 'intermediate', 'advanced']} fieldName="fitnessLevel"/>
        <VerticalGap gapSize={GapSize.md}/>
        <GoogleCloudVideo meta={odw?.media} label="Workout Video"/>
        <Button className="mt-2" size="sm" onClick={() => handleMediaChange('media', '', 'vid')}
                variant="primary">Change Video</Button>
        <VerticalGap gapSize={GapSize.md}/>
        <GoogleCloudImg meta={odw?.thumb} style={{width: '25vw'}} label="Workout Thumbnail"/>
        <Button size="sm"
                onClick={() => handleMediaChange('thumb', '', 'image')}
                variant="primary"
                className="mt-2">
          Change Thumb</Button>
        <VerticalGap gapSize={30}/>
        <Form.Check
          type="switch"
          id="publish-switch"
          label="Published"
          checked={odw?.published}
          onChange={handlePublishedChange} />
        <p className="text-muted font-italic">Published videos will appear in the Lucy app.</p>
      </Form>
      <Modal show={showModal} onHide={() => confirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete workout {odw.title}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => confirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmDelete(true)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <GCSFilePicker show={showPicker}
                     onPick={handleMediaPick}
                     onHide={() => setShowPicker(false)}
                     prefix={pickerPrefix}
                     mimeType={pickerMimeType}/>
    </PageContainer>
  );
};

