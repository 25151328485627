import React, {FC} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import { Client } from 'tlm-common';
import {CheckboxGroupValueDescriptionArrays} from "../../components/formElements/CheckboxGroupValueDescriptionArrays";
import { EQUIPMENT_DESCRIPTION_MAP } from "tlm-common";

type Props = {
  client: Client;
  onChange: (fieldName: string, value: any) => void
}

export const EquipmentForm: FC<Props> = ({client, onChange}) => {

  return (
    <Form className="mt-3">
      <Row>
        <Col>
          <CheckboxGroupValueDescriptionArrays
            label="Equipment"
            onChange={onChange}
            value={client.equipment || []}
            allChoices={EQUIPMENT_DESCRIPTION_MAP}
            fieldName="equipment"/>
        </Col>
      </Row>
    </Form>
  );
};
