import {useEffect, useState} from "react";
import {OnDemandWorkout} from "tlm-common";
import * as api from "../services/api";
import firebase from "firebase";

export const useOnDemandWorkouts = () => {
  const [odws, setOdws] = useState<OnDemandWorkout[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null| firebase.firestore.FirestoreError >(null);

  useEffect(() => {
    loadODWs();
  }, [])

  async function loadODWs() {
    try {
      setError(null);
      setLoading(true);
      const ods = await api.onDemandWorkouts.getAll();
      setOdws(ods);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  return { workouts: odws, loading, error };
}
