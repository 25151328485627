import React, {FC, useEffect, useState} from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import {Table, Form, Badge} from 'react-bootstrap';
import {Journey} from "tlm-common";
import {Link, useHistory} from "react-router-dom";
import { toast } from 'react-toastify';
import {FaPlusCircle} from "react-icons/fa";
import {VerticalGap} from "../../components/layout/VerticalGap";
import { hashMapFilterFactory } from '@bertco/mak-common';
import {useJourneys} from "../../hooks/useJourneys";

const ff = hashMapFilterFactory({
  fieldsOfInterest: ['description', 'title'],
});

export const JourneyListPage: FC = () => {

  const { journeys, loading, error } = useJourneys();
  const [search, setSearch] = useState('');
  const history = useHistory();

  useEffect(()=> {
    if (error!==null) {
      toast(error!.message, { type: toast.TYPE.ERROR })
    }
  }, [error])

  const buildRow = (j: Journey) => {
    return (
      <tr onClick={()=>history.push(`/journey/detail/${j.docId}`)}>
        <td>{j.name}</td>
        <td><Badge variant="success">{j.tag}</Badge></td>
        <td>{j.description}</td>
        <td>{j.published ? '✅' : '❌'}</td>
        <td>{j.videos.length}</td>
      </tr>
    );
  }

  const filtered = journeys.filter( ff(search));

  return (
    <PageContainer title="Journeys" loading={loading}>
      <Form.Group>
        <Form.Control type="text" placeholder="search" onChange={event => {
          setSearch(event.target.value)
        }} value={search}/>
      </Form.Group>
      <Link to="/journey/edit/new"><FaPlusCircle/> Add new journey</Link>
      <VerticalGap gapSize={10}/>
      {filtered.length ? <div>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Tag</th>
            <th>Description</th>
            <th>Published</th>
            <th>Workout Count</th>
          </tr>
          </thead>
          <tbody>
          {journeys.map(buildRow)}
          </tbody>
        </Table>
      </div> : <p className="text-muted font-italic">No journeys found.</p>}
    </PageContainer>
  );
};
