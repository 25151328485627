import { GCF_URL_BASE, GCF_LOCAL_BASE } from "./gcfCommon";
import { jwtRequest } from "./jwtRequests";
import { WorkoutType, Workout } from 'tlm-common';

export interface GenerateWorkoutParams {
  clientId: string;
  workoutType: WorkoutType
}
export async function generateWorkout(params: GenerateWorkoutParams){
  const { clientId, workoutType } = params;
  console.log(params);
  const response = await jwtRequest.post(`${GCF_URL_BASE}/workout`).send({ clientId, workoutType });
  return response.body as { workout: Workout, trace: any };
}
