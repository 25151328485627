
import {firestore} from "../../firebase/firebaseCore";
import {OnDemandWorkout} from "tlm-common";

const odwCollection = firestore.collection('onDemandWorkouts');

export async function getAll() {
  const exQuerySnapshot = await odwCollection.get();
  return exQuerySnapshot.docs.map(d => ({...d.data(), docId: d.id })) as OnDemandWorkout[];
}

export async function get(docId: string) {
  const doc = await odwCollection.doc(docId).get();
  return {...doc.data(), docId: doc.data()?.id } as OnDemandWorkout;
}

export async function createNew( exercise: Partial<OnDemandWorkout> ){
  const newDoc = await odwCollection.add(exercise);
  return newDoc.id;
}

export async function modifyFields( docId: string, newFields: Partial<OnDemandWorkout>){
  delete newFields.docId;
  return odwCollection.doc(docId).update(newFields);
}

export async function destroy( docId: string ){
  return odwCollection.doc(docId).delete();
}
