import React, {FC, useEffect, useState} from 'react';
import {PageContainer} from "../../components/containers/PageContainer";
import {Table, Form} from 'react-bootstrap';
import {OnDemandWorkout} from "tlm-common";
import {Link, useHistory} from "react-router-dom";
import {GoogleCloudImg} from "../../components/images/GoogleCloudImg";
import { toast } from 'react-toastify';
import {FaPlusCircle} from "react-icons/fa";
import {VerticalGap} from "../../components/layout/VerticalGap";
import { hashMapFilterFactory } from '@bertco/mak-common';
import {useOnDemandWorkouts} from "../../hooks/useOnDemandWorkouts";

const ff = hashMapFilterFactory({
  fieldsOfInterest: ['description', 'title'],
});

export const OnDemandWorkoutsListPage: FC = () => {

  const { workouts, loading, error } = useOnDemandWorkouts();
  const [search, setSearch] = useState('');
  const history = useHistory();

  useEffect(()=> {
    if (error!==null) {
      toast(error!.message, { type: toast.TYPE.ERROR })
    }
  }, [error])

  const buildRow = (workout: OnDemandWorkout) => {
    return (
      <tr onClick={()=>history.push(`/odw/detail/${workout.docId}`)}>
        <td><GoogleCloudImg meta={workout?.thumb} style={{width: '80px'}} noGap={true}/></td>
        <td>{workout.published ? '✅' : '❌'}</td>
        <td>{workout.title}</td>
        <td>{workout.description}</td>
        <td>{workout.fitnessLevel}</td>
        <td>{workout.duration}</td>
      </tr>
    );
  }

  const filtered = workouts.filter( ff(search));

  return (
    <PageContainer title="On Demand Workouts" loading={loading}>
      <Form.Group>
        <Form.Control type="text" placeholder="search" onChange={event => {
          setSearch(event.target.value)
        }} value={search}/>
      </Form.Group>
      <Link to="/odw/edit/new"><FaPlusCircle/> Add new on demand workout</Link>
      <VerticalGap gapSize={10}/>
      {filtered.length ? <div>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Thumb</th>
            <th>Published</th>
            <th>Title</th>
            <th>Description</th>
            <th>Fitness Level</th>
            <th>Duration</th>
          </tr>
          </thead>
          <tbody>
          {workouts.map(buildRow)}
          </tbody>
        </Table>
      </div> : <p className="text-muted font-italic">No on demand workouts found.</p>}
    </PageContainer>
  );
};
