import {useEffect, useState} from "react";
import {Journey} from "tlm-common";
import * as api from "../services/api";
import firebase from "firebase";

export const useJourneys = () => {
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null| firebase.firestore.FirestoreError >(null);

  useEffect(() => {
    loadJourneys();
  }, []);

  async function loadJourneys() {
    try {
      setError(null);
      setLoading(true);
      const js = await api.journeys.getAll();
      setJourneys(js);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  return { journeys, loading, error };
}
